import * as Sentry from '@sentry/react'

let dsn = process.env.SENTRY_DSN;
let environment = process.env.NODE_ENV;

if (!dsn) {
  console.error(
    'Missing SENTRY_DSN environment variable. Did you forget to add it to ' +
      'your redwood.toml file in `includeEnvironmentVariables`?'
  )
  console.info(`Copy this into your redwood.toml file:`)
  console.info(`
  includeEnvironmentVariables = [
    "SENTRY_DSN"
  ]

  `)
  console.error('Sentry is disabled for now')
}

Sentry.init({
  dsn,
  environment,
  tunnel: '/api/tunnel',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

export default Sentry
